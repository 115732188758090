<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap pa-6">
      <v-simple-table dense style="width: 100%;" v-if="getTransactions.length > 0" >
        <template v-slot:default>
          <thead>
            <tr>
              <th>Valor</th>
              <th>Descrição</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            <tr style="cursor: pointer" @click="abreNotificacao(item)" v-for="item in getTransactions" :key="item.uid">
              <td class="font-weight-bold fonteCorpo green--text">$ {{ item.value }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.description }}</td>
              <td class="font-weight-bold grey--text fonteCorpo">{{ $moment(item.created_at).locale('pt-br').format('llll') }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-else ></Empty>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getTransactions"])
  },
  methods: {
    ...mapActions(["listTransactions"])
  },
  created() {
    this.listTransactions();
  }
};
</script>
